
import CompareBox from "@/components/compare/CompareBox"
import CompareSidebar from "@/components/compare/CompareSidebar"
import { updateQuery } from "@evercam/shared/utils"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import RightSidebarContent from "@/components/portals/RightSidebarContent"

export default {
  name: "Compare",
  components: {
    CompareBox,
    CompareSidebar,
    RightSidebarContent,
  },
  meta: {
    pageId: AnalyticsEventPageId.Compare,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ query, redirect, $moment, app, route }) {
    const snapshotStore = useSnapshotStore()
    const cameraStore = useCameraStore()
    snapshotStore.reset()
    const compareStore = useCompareStore()
    const routeCameraExid = camelizeKeys(route.params)?.cameraExid

    if (!app.$permissions.user.can.access.compare()) {
      return redirect(cameraStore.cameraRoute)
    }

    let beforeTimestamp = query.before,
      afterTimestamp = query.after

    const shouldUpdateSnapshots =
      !snapshotStore.cameraExid ||
      snapshotStore.cameraExid !== routeCameraExid ||
      !snapshotStore.latestSnapshotTimestamp ||
      !snapshotStore.oldestSnapshotTimestamp ||
      !$moment(snapshotStore.latestSnapshotTimestamp).isValid() ||
      !$moment(snapshotStore.oldestSnapshotTimestamp).isValid()

    if (shouldUpdateSnapshots) {
      await snapshotStore.updateLatestAndOldestSnapshots(routeCameraExid)
    }

    if (
      !afterTimestamp ||
      !$moment(afterTimestamp).isValid() ||
      $moment(afterTimestamp).isAfter(
        $moment(snapshotStore.latestSnapshotTimestamp)
      )
    ) {
      afterTimestamp = snapshotStore.latestSnapshotTimestamp
    }
    if (!beforeTimestamp || !$moment(beforeTimestamp).isValid()) {
      let nearestMilestone
      try {
        nearestMilestone = await EvercamApi.layers.getLayer(routeCameraExid, {
          timestamp: afterTimestamp,
          layerType: LAYER_TYPES.MILESTONE,
        })
      } catch (error) {
        console.error(
          "Unable to retrieve milestone layers for camera",
          routeCameraExid,
          error
        )
      }
      beforeTimestamp =
        nearestMilestone?.startAt || snapshotStore.oldestSnapshotTimestamp
    }

    compareStore.beforeTimestamp = beforeTimestamp
    compareStore.afterTimestamp = afterTimestamp
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.compare"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useCompareStore,
      useCameraStore,
      useLayoutStore
    ),
  },
  watch: {
    beforeTimestamp(timestamp) {
      if (!timestamp) {
        return
      }

      this.updateUrlParams()
    },
    afterTimestamp(timestamp) {
      if (!timestamp) {
        return
      }

      this.updateUrlParams()
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.layoutStore.enableRightSidebar()
  },
  created() {
    this.updateUrlParams()
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
    this.compareStore.$reset()
  },
  methods: {
    updateUrlParams() {
      updateQuery({
        before: this.compareStore.beforeTimestamp,
        after: this.compareStore.afterTimestamp,
      })
    },
  },
}
