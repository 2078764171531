
import CompareExportDialog from "@/components/CompareExportDialog"
import CompareDateTimePicker from "@/components/compare/CompareDateTimePicker"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  name: "CompareSidebar",
  components: {
    CompareExportDialog,
    CompareDateTimePicker,
  },
  data() {
    const tabs = {
      before: 0,
      after: 1,
    }

    return {
      buttons: {
        oldest: "oldest",
        latest: "latest",
      },
      tabs,
      selectedTab: tabs.before,
      isAfterTabActive: false,
    }
  },
  computed: {
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    ...mapStores(useSnapshotStore, useCompareStore, useCameraStore),
  },
  watch: {
    isAfterTabActive(isActive) {
      this.selectedTab = isActive ? this.tabs.after : this.tabs.before
    },
    "compareStore.beforeTimestamp"(timestamp) {
      this.$analytics.saveEvent(AnalyticsEvent.CompareSelectBeforeDate, {
        timestamp,
      })
    },
    "compareStore.afterTimestamp"(timestamp) {
      this.$analytics.saveEvent(AnalyticsEvent.CompareSelectAfterDate, {
        timestamp,
      })
    },
  },
  methods: {
    enableAfterTab() {
      this.isAfterTabActive = true
    },
    disableFirstLastButton(date, button) {
      if (!this.cameraStore.selectedCamera || !date) {
        return
      }
      let timestamp = this.$moment(date)
        .tz(this.cameraStore.selectedCameraTimezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      if (button === this.buttons.oldest) {
        return timestamp === this.snapshotStore.oldestSnapshotTimestamp
      }

      return timestamp === this.snapshotStore.latestSnapshotTimestamp
    },
    selectOldestDate(tab) {
      let eventId
      if (tab === this.tabs.before) {
        this.compareStore.beforeTimestamp =
          this.snapshotStore.oldestSnapshotTimestamp
        eventId = AnalyticsEvent.CompareSelectBeforeOldestDate
      } else {
        this.compareStore.afterTimestamp =
          this.snapshotStore.oldestSnapshotTimestamp
        eventId = AnalyticsEvent.CompareSelectAfterOldestDate
      }

      this.$analytics.saveEvent(eventId)
    },
    selectLatestDate(tab) {
      let eventId
      if (tab === this.tabs.before) {
        this.compareStore.beforeTimestamp =
          this.snapshotStore.latestSnapshotTimestamp
        eventId = AnalyticsEvent.CompareSelectBeforeLatestDate
      } else {
        this.compareStore.afterTimestamp =
          this.snapshotStore.latestSnapshotTimestamp
        eventId = AnalyticsEvent.CompareSelectAfterLatestDate
      }

      this.$analytics.saveEvent(eventId)
    },
  },
}
